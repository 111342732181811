import React from 'react';
import "./about2Styles.css";

const About2 = ({para}) => {
  return (
    <div className="about2">
      <p>{para}</p>
    </div>
  )
}

export default About2
