import React from 'react';
import Navbar2 from '../components/Navbar2.js';
import ClientsIntro from "../components/ClientsIntro.js";
import Brands from "../components/Brands.js";
import LetsWork from "../components/LetsWork.js";
import About from "../components/About.js";
import Footer from '../components/Footer.js';


const Clients = () => {

  const aboutPara = "Through our dedication to excellence and personalized approach, we've forged strong relationships with esteemed brands. Our reputation for delivering outstanding results and fostering collaborative partnerships speaks volumes about our commitment to client satisfaction."


  return (
    <div>
     <Navbar2 />
     <ClientsIntro />
     <Brands />
     <LetsWork />
     <About para={aboutPara} />
     <Footer />  

    </div>
  )
}

export default Clients
