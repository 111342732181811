import React from "react";
import Navbar from "../components/Navbar.js";
import WhatIs from "../components/WhatIs.js";
import Hero from "../components/Hero.js";
import Footer from "../components/Footer.js";
import About2 from "../components/About2.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import whatIsImage from "../assets/whatis-visualeffects.png";
import visualEffectsBackground from "../assets/hero-visualeffects-bg.jpg";

const VisualEffects = () => {
  const paragraph1 =
    "Visual effects, often abbreviated as VFX, are special effects created using computer-generated imagery (CGI) or other techniques to enhance or manipulate live-action footage in movies, TV shows, commercials, and other forms of visual media.";

  const paragraph2 =
    "These effects can include things like adding fantastical creatures, creating explosions, changing the environment, or even making characters fly. Visual effects help bring imaginative worlds to life on screen, making the impossible seem real and adding excitement and spectacle to storytelling.";

  const aboutPara =
    "Venturing into the captivating realm of Visual Effects (VFX) production, our team seamlessly blends artistry with cutting-edge technology to bring imagination to life on the screen.";

  return (
    <div>
      <Navbar />
      <Hero
        background={visualEffectsBackground}
        heading="Visual Effects"
        paragraph="Enhance your videos with stunning visual effects."
      />
      <About2 para={aboutPara} />
      <WhatIs
        heading="What are Visual Effects?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  );
};

export default VisualEffects;
