import React from "react";
import "./contactStyles.css";
import QueryForm from "../components/QueryForm.js";
import Location from "../components/Location.js";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const Contact = () => {

  
  return (
    <div className="contact">
      <div className="contact-content">
        <div className="contact-content-left">
          <p>
            We are a leader Video Agency that connects brands and people through
            customized video content.
          </p>
          <p>
            We are trusted by brands worldwide, providing video content services
            for all kinds of clients, from small startups to big companies.
          </p>
          <p>Whatever your challenge, we would love to discuss it.</p>
        </div>
        <div className="contact-content-right">
          <QueryForm />
        </div>
      </div>
      <div className="followUs">
        <strong>Follow us</strong>
        <div className="social">
          <FaFacebook
            size={20}
            style={{ color: "#000", marginRight: "0.3rem" }}
          />
          <FaInstagram
            size={20}
            style={{ color: "#000", margin: "0 0.3rem" }}
          />
          <FaLinkedin size={20} style={{ color: "#000", margin: "0 0.3rem" }} />
        </div>
      </div>
      <div className="address">
        <div className="address-text">
          <h4>WHERE ARE WE?</h4>
          <h1>Our Offices</h1>
          <h5>New Delhi</h5>
          <p>159-A L/G/F, Gautam Nagar, New Delhi.</p>
        </div>
        <div className="address-location">
          <div className="map-container">
            <Location />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
