import React from "react";
import "./aboutUsHeroStyles.css";
// import companyImage from "../assets/company-image.webp";
import aboutUsImage from "../assets/office-workspace.jpg";

const AboutUsHero = () => {
  return (
    <div className="aboutUsHero">
      <div className="aboutUsHero-image">
        <img src={aboutUsImage} alt="Company" />
      </div>
      <div className="aboutUsHero-text">
        <p>
          We strive for excellence in everything we do, and it all starts with
          transparency and a true passion for our work. We like to partner with
          companies that share those same standards and prioritize
          communication.
        </p>
        <ul>
          <li>ORIGINALITY</li>
          <li>QUALITY</li>
          <li>TRANSPARENCY</li>
          <li>CUSTOMER-CENTRICITY</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUsHero;
