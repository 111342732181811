import React from "react";
import "./howWeWorkStyles.css";
import howWeDoThingsImg1 from "../assets/ourprocess1.webp";
import howWeDoThingsImg2 from "../assets/ourprocess2.webp";
import howWeDoThingsImg3 from "../assets/ourprocess3.webp";
import howWeDoThingsImg4 from "../assets/ourprocess4.webp";
import howWeDoThingsImg5 from "../assets/ourprocess5.webp";
import howWeDoThingsImg6 from "../assets/ourprocess6.webp";
import howWeDoThingsImg7 from "../assets/ourprocess7.webp";
import howWeDoThingsImg8 from "../assets/ourprocess8.webp";
import howWeDoThingsImg9 from "../assets/ourprocess9.webp";
import howWeDoThingsImg10 from "../assets/ourprocess10.webp";

const HowWeWork = () => {
  return (
    <div className="howWeWork">
      <div className="howWeWork-content">
        <div className="howWeWork-content-top">
          <h4>OUR PROCESS</h4>
          <h1>How We Do Things.</h1>
          <p>
            Are you curious about how we tackle the explainer
            video production process? Here's our secret!
          </p>
        </div>
        <div className="howWeDoThings">
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <h4>GETTING STARTED</h4>
              <h1>Creative Brief<span className="point"></span></h1>
              <p>
                We’ll start the explainer video production process by asking you
                some basic questions about your business and video requirements
                to get a general idea of what you’re looking for.
              </p>
            </div>
            <div className="howWeDoThings-content-right">
              <img
                src={howWeDoThingsImg1}
                alt="Our Process"
                height="379px"
                width="382px"
              />
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <img
                src={howWeDoThingsImg2}
                alt="Our Process"
                height="379px"
                width="382px"
              />
            </div>
            <div className="howWeDoThings-content-right">
              <h4>GETTING STARTED</h4>
              <h1>Kick-off Call<span className="point"></span></h1>
              <p>
                We’ll set up an online meeting with both our team and yours.
                After discussing your specific needs, we’ll focus on sharing
                ideas and coming up with the piece's general storyline and art
                direction.
              </p>
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <h4>STEP 1</h4>
              <h1>Script<span className="point"></span></h1>
              <p>
                We craft a clear and compelling script to deliver the right
                message to your audience based on your marketing goals.
              </p>
            </div>
            <div className="howWeDoThings-content-right">
              <img src={howWeDoThingsImg3} alt="Our Process" />
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <img src={howWeDoThingsImg4} alt="Our Process" />
            </div>
            <div className="howWeDoThings-content-right">
              <h4>STEP 2</h4>
              <h1>Storyboard<span className="point"></span></h1>
              <p>
                We create a storyboard that showcases the main actions along
                with descriptions to give you an idea of how these actions will
                unfold and what the explainer video will look like.
              </p>
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <h4>STEP 3</h4>
              <h1>Style Frames<span className="point"></span></h1>
              <p>
                We’ll provide three style frames with the art design of the
                characters and some scenes so you can see how the explainer will
                play out.
              </p>
            </div>
            <div className="howWeDoThings-content-right">
              <img src={howWeDoThingsImg5} alt="Our Process" />
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <img src={howWeDoThingsImg6} alt="Our Process" />
            </div>
            <div className="howWeDoThings-content-right">
              <h4>STEP 4</h4>
              <h1>Illustration<span className="point"></span></h1>
              <p>
                Once we get your approval on the style frames, We’ll start
                working on the design of all the graphic assets. Then, we’ll
                deliver them in a PDF file for you to see how the story will
                visually develop.
              </p>
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <h4>STEP 5</h4>
              <h1>Voice-Over<span className="point"></span></h1>
              <p>
                You’ll select the right voice for your video explainer from our
                list of professional voice-over talents.
              </p>
            </div>
            <div className="howWeDoThings-content-right">
              <img src={howWeDoThingsImg7} alt="Our Process" />
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <img src={howWeDoThingsImg8} alt="Our Process" />
            </div>
            <div className="howWeDoThings-content-right">
              <h4>STEP 6</h4>
              <h1>Animation<span className="point"></span></h1>
              <p>
                Our animators will use the latest 2D digital animation
                techniques to bring the characters and graphic assets to life.
              </p>
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <h4>STEP 7</h4>
              <h1>Music and Sound Effects<span className="point"></span></h1>
              <p>
                We’ll add sound effects and music tracks to your animated
                explainer video to make it even more engaging and fun to watch.
              </p>
            </div>
            <div className="howWeDoThings-content-right">
              <img src={howWeDoThingsImg9} alt="Our Process" />
            </div>
          </div>
          <div className="howWeDoThings-content">
            <div className="howWeDoThings-content-left">
              <img src={howWeDoThingsImg10} alt="Our Process" />
            </div>
            <div className="howWeDoThings-content-right">
              <h4>WE'RE READY!</h4>
              <h1>Final Touches<span className="point"></span></h1>
              <p>
                We carefully go over even the smallest details to ensure your
                video looks just perfect. When everything’s finished, we
                celebrate by grabbing a much-needed cup of coffee and a
                doughnut!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
