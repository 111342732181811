import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Hero from "../components/Hero.js";
import About2 from "../components/About2.js";
import LetsWork from "../components/LetsWork.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import WhatIs from "../components/WhatIs.js";
import Brands from "../components/Brands.js";
import productBackground from "../assets/hero-product-bg.jpg";
import whatIsImage from "../assets/whatis-product.webp";

const ProductVideos = () => {
  const paragraph1 =
    "Product videos are all about showcasing your products and services in ways that get potential customers to buy. Still, while your product will be center-stage, the most effective product videos are built not so much around features exclusively but around how those features improve your customers’ lives. That’s what makes them truly effective.";
  const paragraph2 =
    "2D and 3D animation are the go-to media to bring them to life. However, they can also combine various styles and approaches to best fit your strategy’s goals.";
  const aboutPara = <p>Let’s partner up to create irresistible <strong style={{fontWeight:"600"}}>product videos</strong> that prompt viewers into action. We are a product video company built to help you succeed!</p> 
  return (
    <div>
      <Navbar />
      <Hero
        background={productBackground}
        heading="Product Video Production"
        paragraph="We engineer videos that make your product irresistible."
      />
      <About2 para={aboutPara}/>
      <WhatIs
        heading="What is a product video?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  );
};

export default ProductVideos;
