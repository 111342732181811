import React from 'react';
import "./letsWorkStyles.css";
import { Link } from "react-router-dom";

const LetsWork = () => {
  return (
    <div className="letsWork">
        <div className="letsWork-content">
            <h4>READY?</h4>
            <h2>Let's work<br/> on Your Next Video!</h2>
            <p>Our team will be happy to help bring your ideas to life.</p>
            <Link to="/contact-us"><button className="btn-orange">Contact Us</button></Link> 
        </div>
      
    </div>
  )
}

export default LetsWork
