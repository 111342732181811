import React, { useState } from "react";
import LogoDark from "../assets/rns_media_logo.png";
import LogoWhite from "../assets/rns_media_logo_light.png";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar2Styles.css";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  // const [scroll, setScroll] = useState(true);

  // const changeColor = () => {
  //   if (window.scrollY >= 20) {
  //     setScroll(false);
  //   } else {
  //     setScroll(true);
  //   }
  // };

  // window.addEventListener("scroll", changeColor);

  const handleClick = () => setClick(!click);

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
    setCompanyDropdownOpen(false);
  };

  const toggleCompanyDropdown = () => {
    setCompanyDropdownOpen(!isCompanyDropdownOpen);
    setServicesDropdownOpen(false);
  };

  return (
    <div className= "navbar">
      <div className={`nav-menu ${click ? "active" : ""}`}>
        <div className="nav-menu-logo">
          <Link to="/">
            <img
              src={LogoDark}
              alt="Logo"
              height="75rem"
              width="75rem"
              style={{borderRadius: "5px"}}
            />
          </Link>
        </div>
        <div className="nav-menu-list">
          <ul>
            <li className="dropdown-container">
              <Link>
                <button
                  onClick={toggleServicesDropdown}
                  className="dropdown-btn-color"
                >
                  SERVICES <span>{isServicesDropdownOpen ? "⮝" : "⮟"}</span>
                </button>
              </Link>
              <div
                className={`dropdown-content ${
                  isServicesDropdownOpen ? "show" : ""
                }`}
              >
                <div className="dropdown-menu">
                  <h3>What we bring to the table.</h3>
                  <div className="services-container">
                    <Link to="/explainer-videos">
                      <div className="services">
                        <h4>Explainer Videos</h4>
                        <p>
                          Explainer videos are short videos that help to
                          illustrate complex ideas in simple, engaging, and
                          meaningful ways in less than 2 minutes
                        </p>
                      </div>
                    </Link>
                    <Link to="/live-action-videos">
                      <div className="services">
                        <h4>Live-Action Videos</h4>
                        <p>
                          Live-Action videos feature real people, settings and
                          scenarios and can include animation or other visual
                          affects to enhance the message.
                        </p>
                      </div>
                    </Link>
                    <Link to="/product-videos">
                      <div className="services">
                        <h4>Product Videos</h4>
                        <p>
                          Product videos showcase your product's (or services)
                          features and how they improve your customers' lives.
                        </p>
                      </div>
                    </Link>
                    <Link to="/educational-videos">
                      <div className="services">
                        <h4>Educational Videos</h4>
                        <p>
                          Educational videos help establish your company as an
                          authority in your niche while turning your brand into
                          a reliable resource.
                        </p>
                      </div>
                    </Link>
                    <Link to="/tutorial-videos">
                      <div className="services">
                        <h4>Tutorial & How-to Videos</h4>
                        <p>
                          Tutorials show what your product looks like and how it
                          works so people can get excited and take a step toward
                          the final purchase.
                        </p>
                      </div>
                    </Link>
                    <Link to="/social-media-videos">
                      <div className="services">
                        <h4>Social Media Videos</h4>
                        <p>
                          Your video adapted to the specific technical needs of
                          each social media platform in terms of length and
                          aspect ratio (16:9, 4:5, 9:16).
                        </p>
                      </div>
                    </Link>
                    <Link to="/commercial-videos">
                      <div className="services">
                        <h4>Commercial Videos</h4>
                        <p>
                          Animated commercial ads are short 10 to 30-second
                          videos promoting your company's products and services.
                        </p>
                      </div>
                    </Link>
                    <Link to="/whiteboard-animation">
                      <div className="services">
                        <h4>Whiteboard Animation</h4>
                        <p>
                          Whiteboard videos allows you to transform complicated
                          topics into enjoyable, easy-to-remember, charming
                          videos.
                        </p>
                      </div>
                    </Link>
                    <Link to="/internal-videos">
                      <div className="services">
                        <h4>Internal Videos</h4>
                        <p>
                          Internal Videos are short animated videos that share
                          vital information within your company, keeping
                          everybody on the same page.
                        </p>
                      </div>
                    </Link>
                    <Link to="/audiobook-services">
                      <div className="services">
                        <h4>Audiobook Services</h4>
                        <p>
                          Convert written content into immersive audio
                          experiences, bringing your stories to life through
                          captivating narration.
                        </p>
                      </div>
                    </Link>
                    <Link to="/music-video-production">
                      <div className="services">
                        <h4>Music Video Production</h4>
                        <p>
                          Elevate your music with visually stunning videos,
                          blending captivating imagery with your music to create
                          engaging narratives.
                        </p>
                      </div>
                    </Link>
                    <Link to="/podcast-production">
                      <div className="services">
                        <h4>Podcast Audio & Video Recording</h4>
                        <p>
                          Produce professional podcasts with seamless audio and
                          video integration, enhancing your content's impact and
                          reach.
                        </p>
                      </div>
                    </Link>
                    <Link to="/visual-effects">
                      <div className="services">
                        <h4>Visual Effects</h4>
                        <p>
                          Enhance your videos with stunning visual effects,
                          adding depth and excitement to your storytelling.
                        </p>
                      </div>
                    </Link>
                    <Link to="/documentaries">
                      <div className="services">
                        <h4>Documentaries</h4>
                        <p>
                          Capture real-life stories with authenticity and depth,
                          creating compelling narratives that resonate with your
                          audience.
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <Link to="/portfolio">
                <button
                  className="dropdown-btn-color"
                >
                  PORTFOLIO
                </button>
              </Link> */}
              <Link>
                <button
                  onClick={toggleCompanyDropdown}
                  className="dropdown-btn-color"
                >
                  COMPANY <span>{isCompanyDropdownOpen ? " ⮝" : " ⮟"}</span>
                </button>
              </Link>

              <div
                className={`dropdown-content ${
                  isCompanyDropdownOpen ? "show" : ""
                }`}
              >
                <div className="dropdown-menu">
                  <h3>Company</h3>
                  <div className="services-container">
                    <Link to="/about-us">
                      <div className="services">
                      <h4>About Us</h4>
                      <p>
                        We're RNS Media, a video production company that's here
                        to bring your brand to life with compelling videos that
                        leave a mark.
                      </p>
                      </div>
                    </Link>
                    <Link to="/our-process">
                    <div className="services">
                      <h4>Our Process</h4>
                      <p>
                        See how we've tailored our production pipeline to fit
                        every company's needs and craft unique videos that nail
                        your goals.
                      </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <Link to="/our-clients">
                <button
                  className="dropdown-btn-color"
                >
                  CLIENTS
                </button>
              </Link>
              <Link to="/contact-us">
                <button className="dropdown-btn-color">
                  CONTACT US
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={20} style={{ color:"#000" }} />
          ) : (
            <FaBars size={20} style={{ color:"#000" }} />
          )}
        </div>
        <div className={`overlay-menu ${click ? "active" : ""}`}>
          <div className="overlay-header">
            <Link to="/"><img src={LogoWhite} alt="logo" height="60rem" width="60rem" /></Link>
            <div className="close-icon" onClick={handleClick}>
              <FaTimes size={20} style={{ color: "#fff" }} />
            </div>
          </div>
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/services">SERVICES</Link></li>
            <li><Link to="/our-process">OUR PROCESS</Link></li>
            <li><Link to="/about-us">ABOUT</Link></li>
            <li><Link to="/our-clients">CLIENTS</Link></li>
            <li><Link to="/contact-us">CONTACT US</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
