import React from 'react';
import "./contactUsIntroStyles.css";
import { Link } from "react-router-dom";

const ContactUsIntro = () => {
  return (
    <div className="contactUsIntro">
      <div className="contactUsIntro-content">
      <h4>
          <Link to="/">HOME</Link>/ CONTACT US
        </h4>
        <h1>Let's connect 👋</h1>
      </div>
    </div>
  )
}

export default ContactUsIntro
