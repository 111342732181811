import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import Footer from "../components/Footer.js";
import WhatIs from "../components/WhatIs.js";
import About2 from "../components/About2.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import whatIsImage from "../assets/whatis-musicvideo.png";
import MusicVideoBackground from "../assets/hero-musicvideo-bg.jpg";

const paragraph1 =
  "A music video is a short film or video clip that accompanies a song or piece of music. It's like a mini movie that shows scenes or images related to the song's lyrics or mood, often featuring the artist or band performing the song.";
const paragraph2 =
  "Music videos are a way for musicians to visually express their songs and connect with their audience through storytelling, visuals, and artistic expression. ";

  const aboutPara = "Let us amplify the rhythm, elevate the visuals, and bring your music to life in a way that resonates with both the auditory and visual senses. Elevate your sound, elevate your vision — experience the magic of our music video production expertise."

const MusicVideoProduction = () => {
  return (
    <div>
      <Navbar />
      <Hero
        background={MusicVideoBackground}
        heading="Music Video Production"
        paragraph="Elevate your music with visually stunning videos."
      />
      <About2 para={aboutPara} />
      <WhatIs
        heading="What is a Music Video?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />
    </div>
  );
};

export default MusicVideoProduction;
