import React from "react";
import Navbar2 from "../components/Navbar2.js";
import ContactUsIntro from "../components/ContactUsIntro.js";
import Contact from "../components/Contact.js";
import Footer from "../components/Footer.js";

const ContactUs = () => {
  return (
    <div>
      <Navbar2 />
      <ContactUsIntro />
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactUs;
