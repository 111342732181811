import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import WhatIs from "../components/WhatIs.js";
import About from '../components/About.js';
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import WhyUs from "../components/WhyUs.js";
import Brands from "../components/Brands.js";
import Footer from '../components/Footer.js';
import socialMediaBackground from "../assets/hero-socialmedia-bg.jpg";
import whatIsImage from "../assets/whatis-social-media.webp";



const SocialMediaVideos = () => {
  const paragraph1 = "Video production for social media can involve a bit of everything depending on your strategy's needs — explainer, product, culture, testimonial videos...";
  const paragraph2 = "What defines them is that they have to be carefully designed from the ground up to work within the platform they will be in."
  const paragraph3 = "Different social media platforms require specific technical details, like video length and aspect ratios (16:9, 1:1, 4:5, 9:16). Even the tone and feel of your piece can drastically affect how well it performs."
  const paragraph4 = "A great social media video production accounts for all of these things, and more!"
  const aboutPara = <p>Let’s partner up to create a tailored video production for social media that resonates with your followers and brand. We’re a social media video company that’s here to help you succeed!</p>
  return (
    <div>
      <Navbar />
      <Hero background={socialMediaBackground} heading="Social Media Video Production Company" paragraph="We make videos your audience will like, share, and subscribe to." />
      <About para={aboutPara} />
      <WhatIs heading="What is a Social Media Video?"
        para1={paragraph1}
        para2={paragraph2}
        para3={paragraph3}
        para4={paragraph4}
        image={whatIsImage} />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  )
}

export default SocialMediaVideos
