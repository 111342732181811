import React from 'react';
import Navbar2 from "../components/Navbar2.js"
import ServicesIntro from "../components/ServicesIntro.js";
import ServicesPrimary from "../components/ServicesPrimary.js";
import Footer from "../components/Footer.js";

const Services = () => {
  return (
    <div>
      <Navbar2 />
      <ServicesIntro />
      <ServicesPrimary />
      <Footer />

    </div>
  )
}

export default Services
