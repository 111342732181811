import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import WhatIs from "../components/WhatIs.js";
import About2 from "../components/About2.js";
import whatIsImage from "../assets/whatis-podcast.png";
import Footer from "../components/Footer.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import podcastBackground from "../assets/hero-podcast-bg.jpg";

const PodcastProduction = () => {
  const paragraph1 =
    "A podcast is like a radio show that you can listen to anytime, anywhere, on your phone, computer, or other devices. It's a series of audio episodes, usually focused on a specific topic or theme, like storytelling, interviews, news, or entertainment.";

  const paragraph2 =
    "Podcasts cover a wide range of subjects, from true crime and history to comedy and self-help. You can subscribe to podcasts to automatically receive new episodes as they're released, and you can listen to them on-demand, whenever it's convenient for you.";

  const aboutPara =
    "Podcast video production is the creative process of bringing audio content to life visually, enhancing the podcast experience for a broader audience. This involves recording and editing video footage to complement the audio elements, providing a dynamic and engaging viewing experience.";
  return (
    <div>
      <Navbar />
      <Hero
        background={podcastBackground}
        heading="Podcast Audio & Video Recording"
        paragraph="Produce professional podcasts with seamless audio and video integration."
      />
      <About2 para={aboutPara} />
      <WhatIs
        heading="What is a Podcast?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  );
};

export default PodcastProduction;
