import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import About from "../components/About.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import Footer from "../components/Footer.js";
import WhatIs from "../components/WhatIs.js";
import WhyUs from "../components/WhyUs.js";
import Brands from "../components/Brands.js";
import liveActionBackground from "../assets/hero-liveaction-bg.png";
import whatIsImage from "../assets/whatis-live-action.webp";

const LiveActionVideos = () => {
  const paragraph1 =
    "A live-action explainer video is a type of content that uses live or real-life footage to explain a product, service, or concept. Typically, live-action explainer videos feature real people, settings, and scenarios and can include animation or other visual effects to enhance the message.";

  const paragraph2 =
    "These type of videos are a versatile and effective marketing tool that can be used in various ways, such as in Social Media, crafting Testimonial Videos or Brand Storytelling, etc., to achieve different marketing objectives. By incorporating live-action videos into your marketing strategy, you can create engaging and unique content that resonates with your audience and drives results.";

    const aboutPara = <p>Let's partner up to create a fantastic live-action explainer that fully represents your brand and resonates with your audience. We're here to help you nail your goals and see you succeed!</p>
  return (
    <div>
      <Navbar />
      <Hero
        background={liveActionBackground}
        heading="Live-Action Videos"
        paragraph="Humanize your brand, services, or products with live-action videos."
      />
      <About para={aboutPara} />
      <WhatIs
        heading="What is a live-action explainer video?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  );
};

export default LiveActionVideos;
