import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Hero from "../components/Hero.js";
import About2 from "../components/About2.js";
import WhyUs from "../components/WhyUs.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import Benefits from "../components/Benefits.js";
import WhatIs from "../components/WhatIs.js";
import tutorialBackground from "../assets/hero-tutorial-bg.png";
import whatIsImage from "../assets/whatis-tutorial.webp";

const TutorialVideos = () => {
  const paragraph1 =
    "Tutorial videos – also called How-to-Videos – revolved around a process and how to accomplish it following a step-by-step format. The length might vary, but it’ll typically be around 2 minutes or more.";
  const paragraph2 =
    "The goal of these videos is to show what your product looks like and how it works so people can get excited and take the next step into making the final purchase.";
    const aboutPara = <p>Let's partner up to create <strong style={{fontWeight:"600"}}>tailor How-to videos</strong> that resonate with your audience and brand. We are an animated explainer video company that's here to help you succeed!</p>
  return (
    <div>
      <Navbar />
      <Hero
        background={tutorialBackground}
        heading="Tutorial & How-to Video"
        paragraph="Showcase your product to the world, step-by-step."
      />
      <About2 para={aboutPara} />
      <WhatIs heading="What is a Tutorial Video?" para1={paragraph1} para2={paragraph2} image={whatIsImage} />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />
    </div>
  );
};

export default TutorialVideos;
