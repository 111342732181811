import React from 'react';
import "./whatIsStyles.css";


const WhatIs = ({heading, para1, para2, para3, para4, image}) => {
  return (
    <div className="whatIs">
      <div className="whatIs-text">
       <h1>{heading}</h1>
       <p>{para1}</p> 
       <p>{para2}</p>
       <p>{para3}</p>
       <p>{para4}</p>
      </div>
      <div className="whatIs-picture">
        <img src={image} alt="Live Action" height="470rem" width="480rem" />
      </div>
    </div>
  )
}

export default WhatIs
