import React from "react";
import Navbar2 from "../components/Navbar2.js";
import PortfolioPrimary from "../components/PortfolioPrimary.js";
import PortfolioIntro from "../components/PortfolioIntro.js";
import Brands from "../components/Brands.js";
import Footer from "../components/Footer.js";

const Portfolio = () => {
  

  return (
    <div>
     <Navbar2 />
     <PortfolioIntro />
     <PortfolioPrimary />
     <Brands />
     <Footer />
    </div>
  );
};

export default Portfolio;
