import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import WhatIs from "../components/WhatIs.js";
import About2 from "../components/About2.js";
import LetsWork from "../components/LetsWork.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import Brands from "../components/Brands.js";
import Footer from "../components/Footer.js";
import educationalBackground from "../assets/hero-educational-bg.jpeg";
import whatIsImage from "../assets/whatis-educational.webp";

const EducationalVideos = () => {
  const paragraph1 =
    "Educational videos are, in a nutshell, videos that can educate your audience. They are usually 2 minutes long (or more) and revolve around your audience’s specific pain points, and provide valuable information that helps them understand the solution.";
  const paragraph2 =
    "By using educational videos, you can establish your company as an authority in your niche while turning your brand into a reliable resource to be revisited. Improved brand recognition, positioning, or ranking are just some of the great benefits of these types of animated videos.";
    const aboutPara = <p>Let's partner up to create <strong style={{fontWeight:"600"}}>tailor educational videos</strong> that resonate with your audience and brand. We are a video agency that's here to help you succeed!</p>
  return (
    <div>
      <Navbar />
      <Hero
        background={educationalBackground}
        heading="Educational Animated Videos"
        paragraph="Become the reference in your niche"
      />
      <About2 para={aboutPara}/>
      <WhatIs heading="What is an educational video?" para1={paragraph1} para2={paragraph2} image={whatIsImage} />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />
    </div>
  );
};

export default EducationalVideos;
