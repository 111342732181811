import React from "react";
import "./benefitsStyles.css";


const Benefits = () => {
  return (
    <div className="benefits">
      <h4>BENEFITS</h4>
      <h2>The benefits of using video marketing</h2>
      <div className="benefits-container">
        <div className="benefit">
          <h1>01</h1>
          <h3>Increased Engagement:</h3>
          <p>
            Videos can tell a story, create an emotional connection with the
            viewer, and provide a richer experience than text or images alone.
          </p>
        </div>
        <div className="benefit">
          <h1>02</h1>
          <h3>Increased Conversions:</h3>
          <p>
            Videos can demonstrate how products work and answer common
            questions, all of which can help persuade customers make informed
            purchasing decisions.
          </p>
        </div>
        <div className="benefit">
          <h1>03</h1>
          <h3>Improved SEO:</h3>
          <p>
            Google and other search engines consider videos to be high-quality
            content, which can boost a website's ranking in search results.
          </p>
        </div>
        <div className="benefit">
          <h1>04</h1>
          <h3>Increased Brand Awareness:</h3>
          <p>
            Videos allow businesses to showcase their brand in a memorable way,
            becoming more recognizable and standing out from their competitors.
          </p>
        </div>
        <div className="benefit">
          <h1>05</h1>
          <h3>Improved Training and Onboarding:</h3>
          <p>
            Videos can be used for employee training and onboarding, providing a
            more efficient and effective way to convey information.
          </p>
        </div>
        <div className="benefit">
          <h1>06</h1>
          <h3>Increased Social Media Reach:</h3>
          <p>
            Videos are highly shareable on social media, which can help
            businesses reach a larger audience and increase their social media
            followers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
