import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import WhatIs from "../components/WhatIs.js";
import Footer from "../components/Footer.js";
import About2 from "../components/About2.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import audiobookBackground from "../assets/hero-audiobook-bg.jpg";
import whatIsImage from "../assets/whatis-audiobook.png";

const AudiobookService = () => {
  const paragraph1 =
    "An audiobook is a recorded version of a book that you can listen to instead of reading. It's like having someone read a story to you, so you can enjoy books while doing other activities like driving, exercising, or relaxing.";

  const paragraph2 =
    "Audiobooks often have narrators who bring the characters and story to life through their voices, making the experience engaging and enjoyable.";

  const paragraph3 =
    "Experience the world of literature through captivating audio narratives, where stories come to life through immersive narration and sound design.";

  const aboutPara =
    "Elevate your storytelling with our professional audiobook production service, where passion meets precision to bring your narrative vision to vibrant auditory reality.";

  return (
    <div>
      <Navbar />
      <Hero
        background={audiobookBackground}
        heading="Audiobook Services"
        paragraph="Transform written content into engaging audio experiences."
      />
      <About2 para={aboutPara} />
      <WhatIs
        heading="What is an Audiobook?"
        para1={paragraph1}
        para2={paragraph2}
        para3={paragraph3}
        image={whatIsImage}
      />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />
    </div>
  );
};

export default AudiobookService;
