import React from "react";
import Navbar from "../components/Navbar.js";
import WhatIs from "../components/WhatIs.js";
import Hero from "../components/Hero.js";
import About2 from "../components/About2.js";
import Footer from "../components/Footer.js";
import WhyUs from "../components/WhyUs.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import whatIsImage from "../assets/whatis-documentary.png";
import documentariesBackground from "../assets/hero-documentary-bg.jpg";

const Documentaries = () => {
  const paragraph1 =
    "A documentary is a type of film or video that presents real-life events, people, or issues in a factual and informative way. It's like a non-fiction movie that tells a true story or explores a particular topic through interviews, footage, and narration.";

  const paragraph2 =
    "Documentaries can cover a wide range of subjects, such as history, science, social issues, or personal stories. They aim to educate, inform, and sometimes even inspire viewers by providing a deeper understanding of the world around us.";

  const aboutPara =
    "Documentary video production is the art and process of creating films that capture and depict real-life events, people, or issues. It involves thorough research, planning, and storytelling techniques to present factual information in a compelling and engaging manner.";
  return (
    <div>
      <Navbar />
      <Hero
        background={documentariesBackground}
        heading="Documentaries"
        paragraph="Capture real-life stories with authenticity and depth."
      />
      <About2 para={aboutPara} />
      <WhatIs
        heading="What is a Documentary?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />
    </div>
  );
};

export default Documentaries;
