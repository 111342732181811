import React from "react";
import "./footerStyles.css";
import LogoWhite from "../assets/rns_media_logo_light.png";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-content-left">
          <div className="footer-logo">
            <Link to="/">
            <img
              src={LogoWhite}
              alt="Logo"
              height="100rem"
              width="100rem"
            />
            </Link>
          </div>
          <div className="footer-about">
            <div className="footer-about-info">
              <p>RNS MEDIA</p>
              <p>OFFICES: 159-A L/G/F, Gautam Nagar, New Delhi.</p>
              <p>EMAIL: Contact@rnsmedia.in</p>
            </div>
            <div className="footer-about-social">
              <FaFacebook
                size={20}
                style={{ color: "#fff", marginRight: "0.3rem" }}
              />
              <FaInstagram
                size={20}
                style={{ color: "#fff", margin: "0 0.3rem" }}
              />
              <FaLinkedin
                size={20}
                style={{ color: "#fff", margin: "0 0.3rem" }}
              />
            </div>
          </div>
        </div>
        <div className="footer-content-right">
          <div className="footer-list">
            <div className="footer-list-content">
              <h3>COMPANY</h3>
              <ul>
                <li><Link to="/about-us">WHO WE ARE</Link></li>
                <li><Link to="/portfolio">OUR WORK</Link></li>
                <li><Link to="/our-process">OUR PROCESS</Link></li>
                <li><Link to="/our-clients">CLIENTS</Link></li>
                {/* <li>FAQ</li> */}
                <li><Link to="/contact-us">CONTACT US</Link></li>
              </ul>
            </div>
            <div className="footer-list-content">
              <h3>SERVICES</h3>
              <ul>
                <li><Link to="/explainer-videos">EXPLAINER VIDEOS</Link></li>
                <li><Link to="/live-action-videos">LIVE ACTION</Link></li>
                <li><Link to="/product-videos">PRODUCT VIDEOS</Link></li>
                <li><Link to="/educational-videos">EDUCATIONAL VIDEOS</Link></li>
                <li><Link to="/tutorial-videos">TUTORIAL VIDEOS</Link></li>
                <li><Link to="/social-media-videos">SOCIAL MEDIA VIDEOS</Link></li>
                <li><Link to="/commercial-videos">COMMERCIAL VIDEOS</Link></li>
                <li><Link to="/whiteboard-animation">WHITEBOARD ANIMATION</Link></li>
                <li><Link to="/internal-videos">INTERNAL VIDEOS</Link></li>
                <li><Link to="/audiobook-services">AUDIOBOOK SERVICE</Link></li>
                <li><Link to="/music-video-production">MUSIC VIDEO PRODUCTION</Link></li>
                <li><Link to="/podcast-production">PODCAST AUDIO & VIDEO RECORDING</Link></li>
                <li><Link to="/visual-effects">VISUAL EFFECTS</Link></li>
                <li><Link to="/documentaries">DOCUMENTARIES</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>DEVELOPED BY RNS MEDIA ©</p>
        <p onClick={scrollToTop}><Link>SCROLL TOP ↑</Link></p>
      </div>
    </div>
  );
};

export default Footer;
