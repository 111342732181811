import React from "react";
import "./aboutUsIntroStyles.css";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <div className="aboutUsIntro">
      <div className="aboutUsIntro-content">
        <h4>
          <Link to="/">HOME</Link>/ ABOUT US
        </h4>
        <h1>About us.</h1>
        <p>
        RNS Media is a versatile media firm specializing in Production and Post-Production services. We excel in crafting impactful Corporate Videos, Music Videos, Audiobooks, Podcasts and Documentaries. Our expertise extends to TV and Digital commercials, backed by a talented team of Cinematographers, Writers, Directors, and Technicians, we ensure seamless execution and exceptional results for every project.
        </p>
      </div>
    </div>
  );
};

export default Intro;
