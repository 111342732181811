import React from 'react';
import "./servicesIntroStyles.css";
import { Link } from "react-router-dom";

const ServicesIntro = () => {
  return (
    <div className="servicesIntro">
      <div className="servicesIntro-content">
      <h4><Link to="/">HOME</Link> / SERVICES</h4>
        <h1>What we bring to the table</h1>
      </div>
    </div>
  )
}

export default ServicesIntro
