import React from "react";
import { Link } from "react-router-dom";
import "./clientsIntroStyles.css";

const ClientsIntro = () => {
  return (
    <div className="clientsIntro">
      <div className="clientsIntro-content">
        <h4>
          <Link to="/">HOME</Link>/ CLIENTS
        </h4>
        <h1>Let Our Clients do the talking.</h1>
        <p>
        We are proud to be the trusted choice of renowned brands for their video production needs. With a focus on excellence and client satisfaction, our collaborative approach ensures that every project is executed with creativity and precision, exceeding expectations every time.
        </p>
        <div className="images"></div>
      </div>
    </div>
  );
};

export default ClientsIntro;
