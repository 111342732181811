import React from "react";
import "./ourProcessIntroStyles.css";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <div className="ourProcessIntro">
      <div className="ourProcessIntro-content">
        <h4>
          <Link to="/">HOME</Link>/ HOW WE WORK
        </h4>
        <h1>How we do things.</h1>
        <p>
        Discover our creative and unique video production process, where we blend innovative ideas with precise execution to bring your vision to life. From concept development to final delivery, we prioritize collaboration and excellence at every step.
        </p>
      </div>
    </div>
  );
};

export default Intro;
