import React, { useState } from "react";
import YoutubePlayer from "../components/YoutubePlayer.js";
import "./portfolioPrimaryStyles.css";
import { FaTimes } from "react-icons/fa";
// import { Link } from "react-router-dom";
import image1 from "../assets/portfolio1.webp";
import image2 from "../assets/portfolio2.webp";
import image3 from "../assets/portfolio3.webp";
import image4 from "../assets/portfolio4.webp";
import image5 from "../assets/portfolio5.webp";
import image6 from "../assets/portfolio6.webp";
import image7 from "../assets/portfolio7.webp";
import image8 from "../assets/portfolio8.webp";
import image9 from "../assets/portfolio9.webp";
import image10 from "../assets/portfolio10.webp";
import image11 from "../assets/portfolio11.webp";
import image12 from "../assets/portfolio12.webp";
import image13 from "../assets/portfolio13.webp";
import image14 from "../assets/portfolio14.webp";
import image15 from "../assets/portfolio15.webp";
import image16 from "../assets/portfolio16.webp";
import image17 from "../assets/portfolio17.webp";
import image18 from "../assets/portfolio18.webp";
import image19 from "../assets/portfolio19.webp";
import image20 from "../assets/portfolio20.webp";
import image21 from "../assets/portfolio21.webp";
import image22 from "../assets/portfolio22.webp";
import image23 from "../assets/portfolio23.webp";
import image24 from "../assets/portfolio24.webp";
import image25 from "../assets/portfolio25.webp";
import image26 from "../assets/portfolio26.webp";
import image27 from "../assets/portfolio27.webp";

const PortfolioPrimary = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const openVideoPlayer = (videoId) => {
    setSelectedVideo(videoId);
  };

  const closeVideoPlayer = () => {
    setSelectedVideo(null);
  };

  const portfolioItems = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
  ];
  return (
    <div className="portfolio">
      <div className="portfolio-content">
        {portfolioItems.map((image, index) => (
          <div
            className="portfolio-item"
            key={index}
            onClick={() => openVideoPlayer(`2g811Eo7K8U${index + 1}`)}
          >
            <img
              src={image}
              alt={`Portfolio ${index + 1}`}
              height="365px"
              width="506px"
            />
          </div>
        ))}
      </div>
      {selectedVideo && (
        <div className="video-player-overlay">
          <button className="close-button" onClick={closeVideoPlayer}>
          <FaTimes size={20} />
          </button>
          <YoutubePlayer videoId={selectedVideo} onClose={closeVideoPlayer} />
        </div>
      )}
    </div>
  );
};

export default PortfolioPrimary;
