import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import About2 from "../components/About2.js";
import WhatIs from "../components/WhatIs.js";
import WhyUs from "../components/WhyUs.js";
import LetsWork from "../components/LetsWork.js";
import Benefits from "../components/Benefits.js";
import Brands from "../components/Brands.js";
import Footer from '../components/Footer.js';
import WhiteboardAnimationBackground from "../assets/hero-whiteboard-bg.webp";
import whatIsImage from "../assets/whatis-whiteboard.webp";



const WhiteboardAnimation = () => {
  const paragraph1 =
    "It's a unique type of explainer video designed to get your message across clearly and concisely with its unique visual appeal.";
  const paragraph2 =
    "These videos have become a synonym of creativity with their white backgrounds, drawing hand, and dynamic black-lined animations. Whiteboard video allows you to transform complicated topics into enjoyable, easy-to-remember, charming videos that help you generate leads, brand awareness, and conversions!";
  const aboutPara = <p>Let's partner up to create <strong style={{fontWeight:"600"}}>tailored whiteboard videos</strong> that resonate with your audience and brand. We are a team of whiteboard video creators that's here to help you succeed!</p>  
  return (
    <div>
      <Navbar />
      <Hero background={WhiteboardAnimationBackground} heading="Whiteboard Video Creators Team" paragraph="Providing whiteboard animation services all around the world." />
      <About2 para={aboutPara} />
      <WhatIs heading="What is a Whiteboard Video?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage} />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />
    </div>
  )
}

export default WhiteboardAnimation
