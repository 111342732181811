import React from 'react';
import "./portfolioIntroStyles.css";
import { Link } from "react-router-dom";

const PortfolioIntro = () => {
  return (
    <div className="portfolioIntro">
      <div className="portfolioIntro-content">
        <h4><Link to="/">HOME</Link> / PORTFOLIO</h4>
        <h1>Let our work do the talking.</h1>
      </div>
    </div>
  )
}

export default PortfolioIntro
