import React from "react";
import Card from "./Card.js";
import "./cardContainerStyles.css";
import { Link } from "react-router-dom";
import image1 from "../assets/explainer-video.png";
import image2 from "../assets/live-action-video.png";
import image3 from "../assets/product-video.png";
import image4 from "../assets/educational-video.png";
import image5 from "../assets/tutorial-video.png";
import image6 from "../assets/social-media-video.png";
import image7 from "../assets/commercial-video.png";
import image8 from "../assets/whiteboard-animation.png";
import image9 from "../assets/internal-video.webp";
import image10 from "../assets/audiobook-service.png";
import image11 from "../assets/music-video-production.png";
import image12 from "../assets/podcast.png";
import image13 from "../assets/visual-effects.png";
import image14 from "../assets/documentaries.png";


const CardContainer = () => {
  const cardData = [
    {
      id: 1,
      imageSrc: image1,
      heading: "Explainer Videos",
      text: "Explainer videos are short videos that help to illustrate complex ideas.",
      src: "/explainer-videos",
    },
    {
      id: 2,
      imageSrc: image2,
      heading: "Live-Action Videos",
      text: "Live-Action videos feature real people, settings and scenarios.",
      src: "/live-action-videos",
    },
    {
      id: 3,
      imageSrc: image3,
      heading: "Product Videos",
      text: "They showcase your product's (or service's) features.",
      src: "/product-videos",
    },
    {
      id: 4,
      imageSrc: image4,
      heading: "Educational Videos",
      text: "They help establish your company as an authority in your niche.",
      src: "/educational-videos",
    },
    {
      id: 5,
      imageSrc: image5,
      heading: "Tutorial & How-to Videos",
      text: "Tutorials show what your product looks like and how it works.",
      src: "/tutorial-videos",
    },
    {
      id: 6,
      imageSrc: image6,
      heading: "Social Media Videos",
      text: "Our Video Production Agency creates and adapts your video for social media.",
      src: "/social-media-videos",
    },
    {
      id: 7,
      imageSrc: image7,
      heading: "Commercial Videos",
      text: "Short videos promoting your company's products and services.",
      src: "/commercial-videos",
    },
    {
      id: 8,
      imageSrc: image8,
      heading: "Whiteboard Animation",
      text: "They allow you to transform compliacted topics into charming videos",
      src: "/whiteboard-animation",
    },
    {
      id: 9,
      imageSrc: image9,
      heading: "Internal Videos",
      text: "Animated videos that share vital information within your company",
      src: "/internal-videos",
    },
    {
      id: 10,
      imageSrc: image10,
      heading: "Audiobook Service",
      text: "Transform written content into engaging audiobooks",
      src: "/audiobook-services",
    },
    {
      id: 11,
      imageSrc: image11,
      heading: "Music Video Production",
      text: "Bring your music to life with captivating visuals",
      src: "/music-video-production",
    },
    {
      id: 12,
      imageSrc: image12,
      heading: "Podcast Audio & Video Recording",
      text: "Produce professional podcasts with audio and video",
      src: "/podcast-production",
    },
    {
      id: 13,
      imageSrc: image13,
      heading: "Visual Effects",
      text: "Enhance videos with stunning visual effects",
      src: "/visual-effects",
    },
    {
      id: 14,
      imageSrc: image14,
      heading: "Documentaries",
      text: "Capture real-life stories with depth and authenticity",
      src: "/documentaries",
    },
  ];

  return (
    <div className="card-container">
      {cardData.map((card) => (
        <Link to={card.src}>
        <Card
          key={card.id}
          imageSrc={card.imageSrc}
          heading={card.heading}
          text={card.text}
        />
        </Link>
      ))}
    </div>
  );
};

export default CardContainer;
