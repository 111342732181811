import React from "react";
import "./aboutStyles.css";
import clientLogo1 from "../assets/hero-motocorp-logo.svg";
import clientLogo2 from "../assets/linkedin-logo.svg";
import clientLogo3 from "../assets/sunburn-logo.png";
import clientLogo4 from "../assets/philips-logo.svg";
import clientLogo5 from "../assets/apollo-tyres-logo.svg";

const About = ({ para }) => {
  return (
    <div className="about">
      <div className="about-content">
        <div className="about-content-image">
          <img
            src={clientLogo1}
            alt="Hero Motocorp"
            height="auto"
            width="100px"
          />
          <img src={clientLogo2} alt="LinkedIn" height="auto" width="100px" />
          <img
            src={clientLogo5}
            alt="Apollo Tyres"
            height="auto"
            width="100px"
          />
          <img src={clientLogo3} alt="Yes Bank" height="auto" width="100px" />
          <img src={clientLogo4} alt="Philips" height="auto" width="100px" />
        </div>
        <div className="about-content-text">
          <p>{para}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
