import React from "react";
import { Link } from "react-router-dom";
import "./heroStyles.css";


const Hero = ({background, heading, paragraph}) => {
  return (
    <div className="hero" >
        <div className="mask">
          <img className="bg-image" src={background} alt="Mask" />
        </div>
      <div className="left-content">
        <h1>{heading}</h1>
        <p>{paragraph}</p>
        <Link to="/contact-us"><button className="hero-btn">CONTACT US</button></Link>
        
      </div>
      <div className="right-content"></div>
    </div>
  );
};

export default Hero;
