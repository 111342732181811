import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from "../components/Hero.js";
import About from "../components/About.js";
import WhatIsExplainer from "../components/WhatIsExplainer.js";
import LetsWork from "../components/LetsWork.js";
import Brands from "../components/Brands.js";
import Footer from '../components/Footer.js';
import WhyUs from '../components/WhyUs.js';
import Benefits from "../components/Benefits.js";
import explainerBackground from "../assets/hero-explainer-bg.png";

const ExplainerVideos = () => {

  const aboutPara = <p>Let's partner up to create a fantastic explainer fully tailored to your audience and brand. We're here to help you nail your goals and see you succeed!</p>
  return (
    <div>
      <Navbar />
      <Hero background={explainerBackground} heading="Animated Explainer Videos" paragraph="We're experts at making the complex, simple" />
      <About para={aboutPara} />
      <WhatIsExplainer />
      <WhyUs />
      <LetsWork />
      <Benefits />
      <Brands />
      <Footer />

    </div>
  )
}

export default ExplainerVideos

