import React from "react";
import "./whyUsStyles.css";
import check from "../assets/check.svg";

const WhyUs = () => {
  return (
    <div className="why-us">
      <h4>WHY US?</h4>
      <h2>What Makes Us Unique</h2>
      <div className="why-us-container">
        <div className="why-us-reason">
          <img src={check} alt="checkmark" height="20rem" width="20rem" />
          <div className="content">
            <h3>Extensive Experience</h3>
            <p>
              We’ve created nearly a thousand video explainers in the last 10
              years for both small startups and Fortune 500 companies. Worldwide
              brands such as Amazon, McKesson, FOX, Thermo Fisher, Walmart,
              Vodafone, and more have entrusted us with their projects.
            </p>
          </div>
        </div>
        <div className="why-us-reason">
          <img src={check} alt="checkmark" height="20rem" width="20rem" />
          <div className="content">
            <h3>Fixed Deadlines</h3>
            <p>
              Our clients know what we're working on at any given moment based
              on the Gantt charts we provide to keep track of the schedule. A
              great video explainer is only possible by sticking to a fixed
              turnaround time for each step of the production process and
              listening to the client's specific needs.
            </p>
          </div>
        </div>
        <div className="why-us-reason">
          <img src={check} alt="checkmark" height="20rem" width="20rem" />
          <div className="content">
            <h3>Quality Checks</h3>
            <p>
              With layers upon layers of quality controls, we ensure a
              distinctive signature quality in every piece we produce. This is
              what makes our videos taste different. It's not magic; it's hard
              work and close attention to detail.
            </p>
          </div>
        </div>
        <div className="why-us-reason">
          <img src={check} alt="checkmark" height="20rem" width="20rem" />
          <div className="content">
            <h3>Smooth Process</h3>
            <p>
              We have a clear and highly organized explainer video production
              process with defined steps, layers of quality control, and fixed
              turnaround times that offers our clients a smooth path from start
              to finish.
            </p>
          </div>
        </div>
        <div className="why-us-reason">
          <img src={check} alt="checkmark" height="20rem" width="20rem" />
          <div className="content">
            <h3>Team of Experts</h3>
            <p>We work with talented and passionate designers, producers, directors, animators, and writers who all take great care of each animated explainer video.</p>
          </div>
        </div>
        <div className="why-us-reason">
          <img src={check} alt="checkmark" height="20rem" width="20rem" />
          <div className="content">
            <h3>No Hidden Fees</h3>
            <p>In the first exploratory call, we thoroughly discuss your animated explainer video needs and put together a proposal for bringing your ideas to life. That's it —no hidden fees or costs down the road.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
