import React from "react";
import "./whatWeDoStyles.css";
import CardContainer from "./CardContainer.js";

const WhatWeDo = () => {
  return (
    <div className="whatWeDo">
      <div className="whatWeDo-content">
        <h4>WHAT WE DO</h4>
        <h2>Video Production Services<br/> We Offer</h2>
        <p>
          We offer a variety of services that will help you connect with your
          audience Increase conversions, brand awareness, SEO and more.
        </p>
      </div>
      <CardContainer />
    </div>
  );
};

export default WhatWeDo;
