import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import About from "../components/About.js";
import WhatWeDo from "../components/WhatWeDo.js";
import Benefits from "../components/Benefits.js";
import LetsWork from "../components/LetsWork.js";
import WhyUs from "../components/WhyUs.js";
import Brands from "../components/Brands.js";
import Footer from "../components/Footer.js";
import homeBackground from "../assets/hero-home-bg.jpg";

const Home = () => {
  const aboutPara = (
    <p>
      We’re a Video Production Company that connects brands and people through
      captivating videos. We specialize in ideating, creating, and curating
      exceptional content and films while offering high production values at an
      economical investment.
    </p>
  );
  return (
    <div>
      <Navbar />
      <Hero
        background={homeBackground}
        heading="Video Production Company"
        paragraph="We create videos that connect"
      />
      <About para={aboutPara} />
      <WhatWeDo />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  );
};

export default Home;
