import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop.js";
import Home from "./routes/Home.js";
import ExplainerVideos from "./routes/ExplainerVideos.js";
import LiveActionVideos from "./routes/LiveActionVideos.js";
import ProductVideos from "./routes/ProductVideos.js";
import EducationalVideos from "./routes/EducationalVideos.js";
import TutorialVideos from "./routes/TutorialVideos.js";
import SocialMediaVideos from "./routes/SocialMediaVideos.js";
import CommercialVideos from "./routes/CommercialVideos.js";
import WhiteboardAnimation from "./routes/WhiteboardAnimation.js";
import InternalVideos from "./routes/InternalVideos.js";
import AudiobookService from "./routes/AudiobookService.js";
import MusicVideoProduction from "./routes/MusicVideoProduction.js";
import PodcastProduction from "./routes/PodcastProduction.js";
import VisualEffects from "./routes/VisualEffects.js";
import Documentaries from "./routes/Documentaries.js";
import AboutUs from "./routes/AboutUs.js";
import OurProcess from "./routes/OurProcess.js";
import Portfolio from "./routes/Portfolio.js";
import Clients from "./routes/Clients.js";
import ContactUs from "./routes/ContactUs.js";
import Services from "./routes/Services.js";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explainer-videos" element={<ExplainerVideos />} />
        <Route path="/live-action-videos" element={<LiveActionVideos />} />
        <Route path="/product-videos" element={<ProductVideos />} />
        <Route path="/educational-videos" element={<EducationalVideos />} />
        <Route path="/tutorial-videos" element={<TutorialVideos />} />
        <Route path="/social-media-videos" element={<SocialMediaVideos />} />
        <Route path="/commercial-videos" element={<CommercialVideos />} />
        <Route path="/whiteboard-animation" element={<WhiteboardAnimation />} />
        <Route path="/internal-videos" element={<InternalVideos />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-process" element={<OurProcess />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/our-clients" element={<Clients />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/audiobook-services" element={<AudiobookService/>} />
        <Route path="/music-video-production" element={<MusicVideoProduction/>} />
        <Route path="/podcast-production" element={<PodcastProduction/>} />
        <Route path="/visual-effects" element={<VisualEffects/>} />
        <Route path="/documentaries" element={<Documentaries/>} />
      </Routes>
    </Router>
  );
}

export default App;
