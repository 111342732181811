import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from "../components/Hero.js";
import WhatIs from "../components/WhatIs.js";
import LetsWork from "../components/LetsWork.js";
import About2 from "../components/About2.js";
import WhyUs from '../components/WhyUs.js';
import Brands from "../components/Brands.js";
import Benefits from "../components/Benefits.js";
import Footer from '../components/Footer.js';
import internalBackground from "../assets/hero-internal-bg.webp";
import whatIsImage from "../assets/whatis-internal.webp";


const InternalVideos = () => {
  const paragraph1 = <p>"Internal communications videos are short animated videos that make sharing vital information within your company a breeze."</p>
  const paragraph2 = <p>"These types of videos give you a <strong style={{fontWeight: "600"}}>perfect way to deliver critical data to your employees</strong>, saving hours of work and avoiding mistakes."</p>
  const paragraph3 = "Internal videos help you with the often challenging and essential task of keeping everybody on the same page."
  const aboutPara = <p>Let's partner up to create <strong style={{fontWeight:"600"}}>tailored internal animated videos</strong> that resonate with your audience and brand. We are a video agency that's here to help you succeed!</p>
  return (
    <div>
      <Navbar />
      <Hero background={internalBackground} heading="Internal Animated Video Production" paragraph="Transform the way you communicate with your team." />
      <About2 para={aboutPara} />
      <WhatIs heading="What is an Internal Video?"
        para1={paragraph1}
        para2={paragraph2}
        para3={paragraph3} 
        image={whatIsImage} />
      <WhyUs />
      <LetsWork /> 
      <Benefits />
      <Brands />
      <Footer />
    </div>
  )
}

export default InternalVideos
