import React from "react";
import "./ourProcessHeroStyles.css";
// import companyImage from "../assets/company-image.webp";
import aboutUsImage from "../assets/office-workspace.jpg";

const OurProcessHero = () => {
  return (
    <div className="ourProcessHero">
      <div className="ourProcessHero-content">
        <img src={aboutUsImage} alt="Our Process"  />
      </div>
    </div>
  );
};

export default OurProcessHero;
