import React from "react";
import Navbar from "../components/Navbar.js";
import Hero from "../components/Hero.js";
import WhatIs from "../components/WhatIs.js";
import LetsWork from "../components/LetsWork.js";
import WhyUs from "../components/WhyUs.js";
import Brands from "../components/Brands.js";
import About2 from "../components/About2.js";
import Benefits from "../components/Benefits.js";
import Footer from "../components/Footer.js";
import commercialBackground from "../assets/hero-commercial-bg.jpg";
import whatIsImage from "../assets/whatis-commercial.webp";

const CommercialVideos = () => {
  const paragraph1 =
    "Animated commercial ads are short 10 to 30-second videos promoting your company’s products and services. Unlike other types of marketing videos, great video ads don’t shy away from the fact that they are trying to sell something. They embrace it, finding new and innovative ways to carry your message and being entertaining at the same time.";
  const paragraph2 =
    "Animated video ads can raise brand awareness and conversions, and when it’s fully animated, it allows complete customization, enhancing your branding and relating better with your target audience.";
    const aboutPara = <p>Let's partner up to create <strong style={{fontWeight:"600"}}>tailored animated commercial videos</strong> that resonate with your audience and brand. We are a video agency that's here to help you succeed!</p>

  return (
    <div>
      <Navbar />
      <Hero
        background={commercialBackground}
        heading="Animated Commercial Video Production"
        paragraph="Making commercial ads that stand out at first glance."
      />
      <About2 para={aboutPara}/>
      <WhatIs
        heading="What is an animated commercial Video?"
        para1={paragraph1}
        para2={paragraph2}
        image={whatIsImage}
      />
      <Benefits />
      <LetsWork />
      <WhyUs />
      <Brands />
      <Footer />
    </div>
  );
};

export default CommercialVideos;
