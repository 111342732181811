import React from "react";
import "./servicesPrimaryStyles.css";
import { Link } from "react-router-dom";
import image1 from "../assets/explainer-video-services.png";
import image2 from "../assets/live-action-video-services.jpg";
import image3 from "../assets/product-video-services.jpg";
import image4 from "../assets/educational-video-services.webp";
import image5 from "../assets/tutorial-video-services.png";
import image6 from "../assets/social-media-video-services.png";
import image7 from "../assets/commercial-video-services.png";
import image8 from "../assets/whiteboard-animation-services.jpeg";
import image9 from "../assets/internal-video-services.jpg";
import image10 from "../assets/audiobook-services.webp";
import image11 from "../assets/music-video-services.jpeg";
import image12 from "../assets/podcast-services.webp";
import image13 from "../assets/visual-effects-services.jpeg";
import image14 from "../assets/documentaries-services.webp";



const ServicesPrimary = () => {
 

  return (
    <div className="services-primary-container">
      <div className="services-primary">
       <Link to="/explainer-videos"><img src={image1} alt="Explainer Videos" height="250px" width="350px" />
       <h4>Explainer Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/live-action-videos"><img src={image2} alt="Live-Action Videos" height="250px" width="350px"/>
       <h4>Live-Action Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/product-videos"><img src={image3} alt="Product Videos" height="250px" width="350px" />
       <h4>Product Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/educational-videos"><img src={image4} alt="Educational Videos" height="250px" width="340px" />
       <h4>Educational Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/tutorial-videos"><img src={image5} alt="Tutorial Videos" height="250px" width="340px" />
       <h4>Tutorial & How-to Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/social-media-videos"><img src={image6} alt="Social Media Videos" height="250px" width="340px" />
       <h4>Social Media Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/commercial-videos"><img src={image7} alt="Commercial Videos" height="250px" width="340px" />
       <h4>Commercial Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/whiteboard-animation"><img src={image8} alt="Whiteboard Animation" height="250px" width="340px" />
       <h4>Whiteboard Animation</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/internal-videos"><img src={image9} alt="Internal Videos" height="250px" width="340px" />
       <h4>Internal Videos</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/audiobook-services"><img src={image10} alt="Audibook Services" height="250px" width="340px" />
       <h4>Audiobook Services</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/music-video-production"><img src={image11} alt="Music Video Production" height="250px" width="340px" />
       <h4>Music Video Production</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/podcast-production"><img src={image12} alt="Podcast Audio & Video Recording" height="250px" width="340px" />
       <h4>Podcast Audio & Video Recording</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/visual-effects"><img src={image13} alt="Visual Effects" height="250px" width="340px" />
       <h4>Visual Effects</h4></Link>
      </div>
      <div className="services-primary">
       <Link to="/documentaries"><img src={image14} alt="Documentaries" height="250px" width="340px" />
       <h4>Documentaries</h4></Link>
      </div>
     
    </div>  
  );
};

export default ServicesPrimary;
