import React from 'react';
import Navbar2 from '../components/Navbar2.js';
import HowWeWork from "../components/HowWeWork.js";
import Brands from "../components/Brands.js";
import OurProcessIntro from "../components/OurProcessIntro.js";
import OurProcessHero from "../components/OurProcessHero.js";
import Footer from '../components/Footer.js';


const OurProcess = () => {
  return (
    <div>
      <Navbar2 />
      <OurProcessIntro />
      <OurProcessHero />
      <HowWeWork />
      <Brands />
      <Footer />
    </div>
  )
}

export default OurProcess
