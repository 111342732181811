import React from "react";
import "./brandsStyles.css";
import clientLogo1 from "../assets/boat-logo.png";
import clientLogo2 from "../assets/one8-logo.png";
import clientLogo3 from "../assets/yes-bank-logo.png";
import clientLogo4 from "../assets/sharda-university-logo.png";
import clientLogo5 from "../assets/ather-logo.png";
import clientLogo6 from "../assets/manipal-university-logo.png";
import clientLogo7 from "../assets/hero-motocorp-logo.svg";
import clientLogo8 from "../assets/linkedin-logo.svg";
import clientLogo9 from "../assets/bmw-logo.svg";
import clientLogo10 from "../assets/philips-logo.svg";
import clientLogo11 from "../assets/apollo-tyres-logo.svg";
import clientLogo12 from "../assets/sunburn-logo.png";
import clientLogo13 from "../assets/mahindra-thar-logo.png";
import clientLogo14 from "../assets/reva-university-logo.png";
import clientLogo15 from "../assets/blackberrys-logo.png";
import clientLogo16 from "../assets/trident-group-logo.png";
import clientLogo17 from "../assets/bajaj-pulsar-logo.png";
import clientLogo18 from "../assets/zomato-logo.png";

const Brands = () => {
  return (
    <div className="brands">
      <h3>Brands We've Collaborated With</h3>
      <div className="brands-content">
        <img src={clientLogo1} alt="Boat" height="auto" width="100px" />
        <img src={clientLogo2} alt="One8" height="auto" width="100px" />
        <img src={clientLogo3} alt="Yes Bank" height="auto" width="100px" />
        <img
          src={clientLogo4}
          alt="Sharda University"
          height="auto"
          width="100px"
        />
        <img src={clientLogo5} alt="Ather" height="auto" width="100px" />
        <img
          src={clientLogo6}
          alt="Manipal University"
          height="auto"
          width="100px"
        />
        <img
          src={clientLogo7}
          alt="Hero Motocorp"
          height="auto"
          width="100px"
        />
        <img src={clientLogo8} alt="LinkedIn" height="auto" width="100px" />
        <img src={clientLogo9} alt="Sunburn" height="auto" width="100px" />
        <img src={clientLogo10} alt="Philips" height="auto" width="100px" />
        <img
          src={clientLogo11}
          alt="Apollo Tyres"
          height="auto"
          width="100px"
        />
        <img src={clientLogo12} alt="BMW" height="auto" width="100px" />
        <img
          src={clientLogo13}
          alt="Mahindra Thar"
          height="auto"
          width="100px"
        />
        <img
          src={clientLogo14}
          alt="Reva University"
          height="auto"
          width="100px"
        />
        <img src={clientLogo15} alt="Blackberrys" height="auto" width="100px" />
        <img
          src={clientLogo16}
          alt="Trident Group"
          height="auto"
          width="100px"
        />
        <img
          src={clientLogo17}
          alt="Bajaj Pulsar"
          height="auto"
          width="100px"
        />
        <img src={clientLogo18} alt="Zomato" height="auto" width="100px" />
      </div>
    </div>
  );
};

export default Brands;
