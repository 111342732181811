import React from 'react';
import "./cardStyles.css";

const Card = ({ imageSrc, heading, text }) => {
  return (
    <div className="card">
      <img src={imageSrc} alt="Card Images" height="140rem" width="120rem"/>
      <h3>{heading}</h3>
      <p>{text}</p>
    </div>
  )
}

export default Card
