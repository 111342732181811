import React from 'react';
import Navbar2 from '../components/Navbar2.js';
import AboutUsIntro from "../components/AboutUsIntro.js";
import AboutUsHero from "../components/AboutUsHero.js";
import Brands from "../components/Brands.js";
import Footer from '../components/Footer.js';


const AboutUs = () => {


  return (
    <div>
      <Navbar2 />
      <AboutUsIntro />
      <AboutUsHero />
      <Brands />
      <Footer />
      
    </div>
  )
}

export default AboutUs
