import React from 'react';
import check from "../assets/check.svg";
import explainerImage from "../assets/whatis-explainer.webp";
import "./whatIsExplainerStyles.css";


const whatIsExplainer = () => {
  return (
    <div className="whatIsExplainer">
      <div className="whatIsExplainer-text">
       <h1>What is an animated explainer video?</h1>
       <p>Video explainers are short videos that illustrate complex ideas in simple and engaging ways so your audience can easily understand your message in less than 2 minutes. The goal is to describe your company's products (or services) focusing on benefits rather than features, so they resonate with your audience's pain points in a meaningful way — introducing your solution as the best solution.</p>
       <p>By using an animated explainer video, your company can:</p>
       <ul>
        <li><img src={check} alt="Tick Mark" height="20rem" width="20rem" />Explain your message in seconds</li>
        <li><img src={check} alt="Tick Mark" height="20rem" width="20rem" />Increase conversions</li>
        <li><img src={check} alt="Tick Mark" height="20rem" width="20rem" />Generate Leads</li>
        <li><img src={check} alt="Tick Mark" height="20rem" width="20rem" />Reduce bounce rates</li>
        <li><img src={check} alt="Tick Mark" height="20rem" width="20rem" />Foster brand awareness</li>
       </ul>
      </div>
      <div className="whatIsExplainer-picture">
       <img src={explainerImage} alt="Animated Explainer Video" height="530px" width="455px" />
      </div>
    </div>
  )
}

export default whatIsExplainer
